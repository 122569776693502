import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, CircularProgress, Grid } from '@mui/material';
import { getPortfolioValue } from '../../services/cryptoService';
import { Transaction } from '../../types/transaction';

interface PortfolioValueProps {
  transactions: Transaction[];
}

interface PortfolioSummary {
  investedCapital: number;
  currentValue: number;
  profitLoss: number;
  profitLossPercentage: number;
}

const PortfolioValue: React.FC<PortfolioValueProps> = ({ transactions }) => {
  const [portfolioSummary, setPortfolioSummary] = useState<PortfolioSummary>({
    investedCapital: 0,
    currentValue: 0,
    profitLoss: 0,
    profitLossPercentage: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const calculatePortfolioSummary = async () => {
      try {
        setIsLoading(true);
        
        // Calculer les holdings actuels
        const holdings: { [key: string]: number } = {};
        let investedCapital = 0;
        
        // Calculer d'abord le capital investi (uniquement les achats)
        transactions.forEach(transaction => {
          if (transaction.type === 'achat') {
            investedCapital += transaction.total;
          }
        });

        // Calculer ensuite les holdings actuels
        transactions.forEach(transaction => {
          const amount = transaction.amount;
          if (!holdings[transaction.crypto]) {
            holdings[transaction.crypto] = 0;
          }
          
          switch (transaction.type) {
            case 'achat':
              holdings[transaction.crypto] += amount;
              break;
            case 'vente':
              holdings[transaction.crypto] -= amount;
              break;
            case 'don_entrant':
              holdings[transaction.crypto] += amount;
              break;
            case 'don_sortant':
              holdings[transaction.crypto] -= amount;
              break;
          }
        });

        // Récupérer les valeurs actuelles
        const holdingsArray = Object.entries(holdings)
          .filter(([_, amount]) => amount > 0)
          .map(([symbol, amount]) => ({ symbol, amount }));

        const portfolio = await getPortfolioValue(holdingsArray);
        const currentValue = portfolio.totalValue;

        // Calculer les profits/pertes
        const profitLoss = currentValue - investedCapital;
        const profitLossPercentage = (investedCapital !== 0) 
          ? (profitLoss / investedCapital) * 100 
          : 0;

        setPortfolioSummary({
          investedCapital,
          currentValue,
          profitLoss,
          profitLossPercentage
        });
        setError(null);
      } catch (err) {
        setError('Erreur lors de la récupération des prix');
        console.error('Error updating portfolio value:', err);
      } finally {
        setIsLoading(false);
      }
    };

    calculatePortfolioSummary();
    // Mettre à jour toutes les 5 minutes
    const interval = setInterval(calculatePortfolioSummary, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [transactions]);

  const formatCurrency = (value: number) => {
    return value.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });
  };

  if (error) {
    return (
      <Card sx={{ mb: 2, backgroundColor: 'error.light' }}>
        <CardContent>
          <Typography color="error">
            {error}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Résumé du Portefeuille
        </Typography>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ p: 2, borderRadius: 1, bgcolor: 'background.paper' }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Capital Investi
                </Typography>
                <Typography variant="h6" component="div">
                  {formatCurrency(portfolioSummary.investedCapital)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ p: 2, borderRadius: 1, bgcolor: 'background.paper' }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Valeur Actuelle
                </Typography>
                <Typography variant="h6" component="div">
                  {formatCurrency(portfolioSummary.currentValue)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ 
                p: 2, 
                borderRadius: 1, 
                bgcolor: 'background.paper',
                color: portfolioSummary.profitLoss >= 0 ? 'success.main' : 'error.main'
              }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Gains/Pertes
                </Typography>
                <Typography variant="h6" component="div">
                  {formatCurrency(portfolioSummary.profitLoss)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ 
                p: 2, 
                borderRadius: 1, 
                bgcolor: 'background.paper',
                color: portfolioSummary.profitLossPercentage >= 0 ? 'success.main' : 'error.main'
              }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Performance
                </Typography>
                <Typography variant="h6" component="div">
                  {portfolioSummary.profitLossPercentage.toFixed(2)}%
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioValue;
