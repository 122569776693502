import React from 'react';
import { Box } from '@mui/material';
import backgroundImage from '../../assets/image_fond.webp';

const Background: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -2,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundColor: 'rgba(15, 23, 42, 0.325)', // Réduit l'opacité de 50%
          backdropFilter: 'blur(2px)',
        }}
      />
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          zIndex: 0,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Background;
