import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCbUUee-gI23HNtIs2SgsLGaNqDeEghny8",
  authDomain: "cryptotax-cffdf.firebaseapp.com",
  projectId: "cryptotax-cffdf",
  storageBucket: "cryptotax-cffdf.appspot.com",
  messagingSenderId: "416320292358",
  appId: "1:416320292358:web:ac9727351c897eeb8c6587",
  measurementId: "G-DHEMZXL1LE"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;
