import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Alert,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from 'date-fns/locale';

interface ExampleResult {
  buyDate: Date;
  sellDate: Date;
  crypto: string;
  amount: number;
  buyPrice: number;
  sellPrice: number;
  totalBuy: number;
  totalSell: number;
  capitalGain: number;
  flatTax: number;
  socialContributions: number;
  totalTax: number;
}

interface ExampleData {
  title: string;
  description: string;
  data: {
    buyDate: Date;
    sellDate: Date;
    crypto: string;
    amount: string;
    buyPrice: string;
    sellPrice: string;
  };
}

interface Examples {
  [key: string]: ExampleData;
}

const ANNUAL_THRESHOLD = 305;
const FLAT_TAX_RATE = 12.8;
const SOCIAL_CONTRIBUTIONS_RATE = 17.2;

const examples: Examples = {
  example1: {
    title: 'Exemple 1: Vente sous le seuil',
    description: 'Achat et vente de BTC pour un montant inférieur au seuil de 305€',
    data: {
      buyDate: new Date('2024-01-01'),
      sellDate: new Date('2024-06-01'),
      crypto: 'BTC',
      amount: '0.01',
      buyPrice: '20000',
      sellPrice: '25000',
    }
  },
  example2: {
    title: 'Exemple 2: Plus-value imposable',
    description: 'Achat et vente d\'ETH avec une plus-value significative',
    data: {
      buyDate: new Date('2024-01-01'),
      sellDate: new Date('2024-12-01'),
      crypto: 'ETH',
      amount: '1',
      buyPrice: '2000',
      sellPrice: '8000',
    }
  },
};

const ExamplePage: React.FC = () => {
  const [result, setResult] = useState<ExampleResult | null>(null);
  const [selectedExample, setSelectedExample] = useState<string>('');

  // État du formulaire
  const [formData, setFormData] = useState({
    buyDate: new Date(),
    sellDate: new Date(),
    crypto: 'BTC',
    amount: '',
    buyPrice: '',
    sellPrice: '',
  });

  const calculateExample = (data: typeof formData) => {
    const amount = parseFloat(data.amount);
    const buyPrice = parseFloat(data.buyPrice);
    const sellPrice = parseFloat(data.sellPrice);

    const totalBuy = amount * buyPrice;
    const totalSell = amount * sellPrice;
    const capitalGain = totalSell - totalBuy;

    // Si le total des ventes est sous le seuil, pas d'imposition
    if (totalSell <= ANNUAL_THRESHOLD) {
      return {
        ...data,
        amount,
        buyPrice,
        sellPrice,
        totalBuy,
        totalSell,
        capitalGain,
        flatTax: 0,
        socialContributions: 0,
        totalTax: 0,
      };
    }

    const flatTax = Math.max(0, capitalGain * (FLAT_TAX_RATE / 100));
    const socialContributions = Math.max(0, capitalGain * (SOCIAL_CONTRIBUTIONS_RATE / 100));

    return {
      ...data,
      amount,
      buyPrice,
      sellPrice,
      totalBuy,
      totalSell,
      capitalGain,
      flatTax,
      socialContributions,
      totalTax: flatTax + socialContributions,
    };
  };

  const handleExampleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedExample(value);
    
    if (value === '') {
      // Reset form to default values
      setFormData({
        buyDate: new Date(),
        sellDate: new Date(),
        crypto: '',
        amount: '',
        buyPrice: '',
        sellPrice: '',
      });
      setResult(null);
      return;
    }

    const example = examples[value as keyof typeof examples];
    if (example) {
      setFormData({
        buyDate: example.data.buyDate,
        sellDate: example.data.sellDate,
        crypto: example.data.crypto,
        amount: example.data.amount,
        buyPrice: example.data.buyPrice,
        sellPrice: example.data.sellPrice,
      });
      const result = calculateExample(example.data);
      setResult(result);
    }
  };

  const handleCalculate = () => {
    if (!formData.amount || !formData.buyPrice || !formData.sellPrice) {
      return;
    }
    const result = calculateExample(formData);
    setResult(result);
  };

  const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Exemple de calcul de plus-value
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Comment fonctionne le calcul des plus-values ?
        </Typography>
        <Typography paragraph>
          En France, les plus-values sur les crypto-monnaies sont imposées selon les règles suivantes :
        </Typography>
        <Typography component="div" sx={{ mb: 2 }}>
          <ul>
            <li>Seuil d'imposition : {ANNUAL_THRESHOLD}€ de cessions par an</li>
            <li>Si les cessions annuelles dépassent ce seuil, la plus-value est imposée à 30% :
              <ul>
                <li>Impôt forfaitaire : {FLAT_TAX_RATE}%</li>
                <li>Prélèvements sociaux : {SOCIAL_CONTRIBUTIONS_RATE}%</li>
              </ul>
            </li>
            <li>La plus-value est calculée sur la différence entre le prix de vente et le prix d'achat</li>
          </ul>
        </Typography>
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Simulateur avec exemples
        </Typography>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Choisir un exemple</InputLabel>
          <Select
            value={selectedExample}
            onChange={handleExampleSelect}
            label="Choisir un exemple"
          >
            <MenuItem value="">
              <em>Personnalisé</em>
            </MenuItem>
            {Object.entries(examples).map(([key, example]) => (
              <MenuItem key={key} value={key}>
                {example.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedExample && (
          <Alert severity="info" sx={{ mb: 3 }}>
            {examples[selectedExample as keyof typeof examples].description}
          </Alert>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <DatePicker
                label="Date d'achat"
                value={formData.buyDate}
                onChange={(date) => setFormData(prev => ({ ...prev, buyDate: date || new Date() }))}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <DatePicker
                label="Date de vente"
                value={formData.sellDate}
                onChange={(date) => setFormData(prev => ({ ...prev, sellDate: date || new Date() }))}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Crypto-monnaie"
              value={formData.crypto}
              onChange={handleInputChange('crypto')}
            >
              <MenuItem value="BTC">Bitcoin (BTC)</MenuItem>
              <MenuItem value="ETH">Ethereum (ETH)</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Montant"
              type="number"
              value={formData.amount}
              onChange={handleInputChange('amount')}
              inputProps={{ step: "0.00000001" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Prix d'achat unitaire (€)"
              type="number"
              value={formData.buyPrice}
              onChange={handleInputChange('buyPrice')}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Prix de vente unitaire (€)"
              type="number"
              value={formData.sellPrice}
              onChange={handleInputChange('sellPrice')}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleCalculate}
              disabled={!formData.amount || !formData.buyPrice || !formData.sellPrice}
              fullWidth
            >
              Calculer
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {result && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Résultat du calcul
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Montant total d'achat
              </Typography>
              <Typography variant="body1">
                {formatCurrency(result.totalBuy)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Montant total de vente
              </Typography>
              <Typography variant="body1">
                {formatCurrency(result.totalSell)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Plus-value
              </Typography>
              <Typography variant="body1" color={result.capitalGain >= 0 ? 'success.main' : 'error.main'}>
                {formatCurrency(result.capitalGain)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Seuil d'imposition dépassé ?
              </Typography>
              <Typography variant="body1">
                {result.totalSell > ANNUAL_THRESHOLD ? 'Oui' : 'Non'}
              </Typography>
            </Grid>

            {result.totalSell > ANNUAL_THRESHOLD && (
              <>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>
                    Détail des taxes
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Impôt forfaitaire ({FLAT_TAX_RATE}%)
                  </Typography>
                  <Typography variant="body1">
                    {formatCurrency(result.flatTax)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Prélèvements sociaux ({SOCIAL_CONTRIBUTIONS_RATE}%)
                  </Typography>
                  <Typography variant="body1">
                    {formatCurrency(result.socialContributions)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Total des taxes
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {formatCurrency(result.totalTax)}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default ExamplePage;
