import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  InputAdornment,
  ListSubheader,
  Divider,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { Transaction } from '../../types/transaction';
import fr from 'date-fns/locale/fr';

interface TransactionFormProps {
  onSubmit: (transaction: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => void;
  onClose: () => void;
  initialData?: Transaction | null;
}

const TransactionForm: React.FC<TransactionFormProps> = ({
  onSubmit,
  onClose,
  initialData
}) => {
  const [type, setType] = useState<'achat' | 'vente' | 'don_entrant' | 'don_sortant'>('achat');
  const [crypto, setCrypto] = useState('');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [fees, setFees] = useState('0');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (initialData) {
      setType(initialData.type);
      setCrypto(initialData.crypto);
      setAmount(initialData.amount.toString());
      setPrice(initialData.price.toString());
      setFees(initialData.fees?.toString() || '0');
      setNotes(initialData.notes || '');
      setDate(initialData.date ? new Date(initialData.date) : new Date());
    }
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!crypto || !amount || !price) {
      alert('Veuillez remplir tous les champs obligatoires');
      return;
    }

    const transaction = {
      type,
      crypto: crypto.toUpperCase(),
      amount: parseFloat(amount),
      price: parseFloat(price),
      total: parseFloat(amount) * parseFloat(price),
      fees: parseFloat(fees) || 0,
      notes,
      date: date?.getTime() || new Date().getTime(),
    };

    onSubmit(transaction);
  };

  const cryptoList = [
    // Principales cryptomonnaies
    { symbol: 'BTC', name: 'Bitcoin' },
    { symbol: 'ETH', name: 'Ethereum' },
    { symbol: 'BNB', name: 'Binance Coin' },
    { symbol: 'ADA', name: 'Cardano' },
    { symbol: 'DOT', name: 'Polkadot' },
    { symbol: 'XRP', name: 'Ripple' },
    { symbol: 'SOL', name: 'Solana' },
    { symbol: 'DOGE', name: 'Dogecoin' },
    { symbol: 'AVAX', name: 'Avalanche' },
    { symbol: 'MATIC', name: 'Polygon' },
    // Stablecoins
    { symbol: 'USDT', name: 'Tether' },
    { symbol: 'USDC', name: 'USD Coin' },
    { symbol: 'BUSD', name: 'Binance USD' },
    { symbol: 'DAI', name: 'Dai' },
    { symbol: 'TUSD', name: 'True USD' },
    { symbol: 'USDP', name: 'Pax Dollar' },
    { symbol: 'FRAX', name: 'Frax' },
    { symbol: 'LUSD', name: 'Liquity USD' },
    { symbol: 'GUSD', name: 'Gemini Dollar' },
    { symbol: 'EUROC', name: 'Euro Coin' }
  ];

  const [customCrypto, setCustomCrypto] = useState(false);
  const [customSymbol, setCustomSymbol] = useState('');
  const [customName, setCustomName] = useState('');

  const handleCryptoChange = (value: string) => {
    if (value === 'custom') {
      setCustomCrypto(true);
      setCrypto('');
    } else {
      setCustomCrypto(false);
      setCrypto(value);
    }
  };

  const handleCustomCryptoSubmit = () => {
    if (customSymbol && customName) {
      const formattedSymbol = customSymbol.toUpperCase();
      setCrypto(formattedSymbol);
      setCustomCrypto(false);
      setCustomSymbol('');
      setCustomName('');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {initialData ? 'Modifier la transaction' : 'Nouvelle transaction'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Type de transaction</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value as 'achat' | 'vente' | 'don_entrant' | 'don_sortant')}
                label="Type de transaction"
                required
              >
                <MenuItem value="achat">Achat</MenuItem>
                <MenuItem value="vente">Vente</MenuItem>
                <MenuItem value="don_entrant">Don reçu</MenuItem>
                <MenuItem value="don_sortant">Don envoyé</MenuItem>
              </Select>
            </FormControl>

            {!customCrypto ? (
              <FormControl fullWidth>
                <InputLabel>Crypto-monnaie</InputLabel>
                <Select
                  value={crypto}
                  onChange={(e) => handleCryptoChange(e.target.value)}
                  label="Crypto-monnaie"
                  required
                >
                  <ListSubheader>Principales Cryptomonnaies</ListSubheader>
                  {cryptoList.slice(0, 10).map((coin) => (
                    <MenuItem key={coin.symbol} value={coin.symbol}>
                      {coin.name} ({coin.symbol})
                    </MenuItem>
                  ))}
                  <ListSubheader>Stablecoins</ListSubheader>
                  {cryptoList.slice(10).map((coin) => (
                    <MenuItem key={coin.symbol} value={coin.symbol}>
                      {coin.name} ({coin.symbol})
                    </MenuItem>
                  ))}
                  <Divider sx={{ my: 1 }} />
                  <MenuItem value="custom">
                    <AddIcon sx={{ mr: 1 }} />
                    Ajouter une autre crypto-monnaie
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="Symbole de la crypto-monnaie"
                  value={customSymbol}
                  onChange={(e) => setCustomSymbol(e.target.value.toUpperCase())}
                  required
                  helperText="Ex: BTC, ETH, etc."
                  inputProps={{ maxLength: 10 }}
                />
                <TextField
                  label="Nom de la crypto-monnaie"
                  value={customName}
                  onChange={(e) => setCustomName(e.target.value)}
                  required
                  helperText="Ex: Bitcoin, Ethereum, etc."
                />
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    variant="contained"
                    onClick={handleCustomCryptoSubmit}
                    disabled={!customSymbol || !customName}
                    fullWidth
                  >
                    Valider
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setCustomCrypto(false);
                      setCustomSymbol('');
                      setCustomName('');
                    }}
                    fullWidth
                  >
                    Annuler
                  </Button>
                </Box>
              </Box>
            )}

            <TextField
              label="Montant"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              InputProps={{
                endAdornment: crypto ? (
                  <InputAdornment position="end">{crypto}</InputAdornment>
                ) : null,
              }}
            />

            <TextField
              label="Prix unitaire"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
            />

            {amount && price && (
              <Typography variant="body2" color="text.secondary">
                Total: {(parseFloat(amount) * parseFloat(price)).toFixed(2)} EUR
              </Typography>
            )}

            <TextField
              label="Frais"
              type="number"
              value={fees}
              onChange={(e) => setFees(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
            />

            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue) => setDate(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true
                }
              }}
            />

            <TextField
              label="Notes"
              multiline
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button type="submit" variant="contained" color="primary">
            {initialData ? 'Modifier' : 'Ajouter'}
          </Button>
        </DialogActions>
      </form>
    </LocalizationProvider>
  );
};

export default TransactionForm;
