import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Transaction } from '../../types/transaction';
import { getUserTransactions } from '../../services/transactions';
import { format } from 'date-fns';

const ANNUAL_THRESHOLD = 305;
const FIAT_CURRENCY = 'EUR';

interface TaxResult {
  transaction: Transaction;
  capitalGain: number;
  flatTax: number;
  socialContributions: number;
  totalTax: number;
}

interface ExampleData {
  title: string;
  description: string;
  data: {
    buyDate: Date;
    sellDate: Date;
    crypto: string;
    amount: string;
    buyPrice: string;
    sellPrice: string;
  };
}

interface Examples {
  [key: string]: ExampleData;
}

const examples: Examples = {
  example1: {
    title: "Example 1",
    description: "Basic Bitcoin transaction",
    data: {
      buyDate: new Date("2023-01-01"),
      sellDate: new Date("2023-12-01"),
      crypto: "BTC",
      amount: "0.5",
      buyPrice: "20000",
      sellPrice: "25000"
    }
  },
  example2: {
    title: "Example 2",
    description: "Ethereum transaction",
    data: {
      buyDate: new Date("2023-02-01"),
      sellDate: new Date("2023-11-01"),
      crypto: "ETH",
      amount: "2",
      buyPrice: "1500",
      sellPrice: "2000"
    }
  }
};

const TaxSimulator: React.FC = () => {
  const [transactionResults, setTransactionResults] = useState<TaxResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedExample, setSelectedExample] = useState<string>('');

  const getTaxRates = (year: number) => {
    return {
      flatTax: 12.8,
      socialContributions: 17.2
    };
  };

  const formatDate = (date: number | Date): string => {
    if (date instanceof Date) {
      return format(date, 'dd/MM/yyyy');
    }
    return format(new Date(date), 'dd/MM/yyyy');
  };

  const calculateTaxes = async () => {
    try {
      setLoading(true);
      setError(null);

      // Récupérer les transactions depuis Firebase
      const transactions = await getUserTransactions();

      // Grouper les transactions par année
      const transactionsByYear = transactions.reduce((acc: { [key: string]: Transaction[] }, transaction) => {
        const date = new Date(transaction.date);
        const year = date.getFullYear().toString();
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(transaction);
        return acc;
      }, {});

      const results = Object.entries(transactionsByYear).flatMap(([year, yearTransactions]) => {
        // Ne calculer le total des cessions que pour les ventes en FIAT
        const yearSales = yearTransactions
          .filter(t => t.type === 'vente');  // On considère toutes les ventes comme étant en EUR si non spécifié
        
        const totalSales = yearSales.reduce((sum, t) => sum + t.total, 0);

        console.log(`Année ${year} - Total des cessions: ${totalSales}€ (seuil: ${ANNUAL_THRESHOLD}€)`);

        // Si le total des cessions est inférieur au seuil, pas d'imposition pour toute l'année
        if (totalSales <= ANNUAL_THRESHOLD) {
          console.log(`${year}: Total des cessions (${totalSales}€) inférieur au seuil de ${ANNUAL_THRESHOLD}€ - Pas d'imposition pour l'année`);
          return yearSales.map(transaction => ({
            transaction,
            capitalGain: 0,
            flatTax: 0,
            socialContributions: 0,
            totalTax: 0
          }));
        }

        // Si on dépasse le seuil, calculer les plus-values et les taxes pour toutes les ventes de l'année
        return yearSales.map(transaction => {
          const date = transaction.date instanceof Date 
            ? transaction.date 
            : new Date(transaction.date);
          const rates = getTaxRates(parseInt(year));
          
          // Trouver les achats antérieurs pour cette crypto
          const purchases = yearTransactions
            .filter(t => 
              t.type === 'achat' && 
              t.crypto === transaction.crypto && 
              (t.date instanceof Date ? t.date : new Date(t.date)) < date
            )
            .sort((a, b) => {
              const dateA = a.date instanceof Date ? a.date : new Date(a.date);
              const dateB = b.date instanceof Date ? b.date : new Date(b.date);
              return dateA.getTime() - dateB.getTime();
            });

          // Calculer le prix moyen pondéré d'acquisition (PMPA)
          let totalPurchaseAmount = 0;
          let totalPurchaseCost = 0;
          for (const purchase of purchases) {
            totalPurchaseAmount += purchase.amount;
            totalPurchaseCost += purchase.total;
          }

          const pmpa = totalPurchaseCost / totalPurchaseAmount || 0;
          const acquisitionCost = transaction.amount * pmpa;
          const capitalGain = transaction.total - acquisitionCost;

          console.log('Détails de la transaction:', {
            annee: year,
            date: formatDate(date instanceof Date ? date.getTime() : date),
            type: transaction.type,
            crypto: transaction.crypto,
            montant: transaction.amount,
            prixUnitaire: transaction.price,
            total: transaction.total,
            pmpa: pmpa.toFixed(2) + '€',
            coutAcquisition: acquisitionCost.toFixed(2) + '€',
            plusValue: capitalGain.toFixed(2) + '€',
            totalCessionsAnnuelles: totalSales.toFixed(2) + '€',
            imposable: totalSales > ANNUAL_THRESHOLD ? 'Oui' : 'Non',
            tauxImposition: {
              flatTax: rates.flatTax + '%',
              prelevementsSociaux: rates.socialContributions + '%'
            }
          });
          
          const flatTaxAmount = Math.max(0, capitalGain * (rates.flatTax / 100));
          const socialContributionsAmount = Math.max(0, capitalGain * (rates.socialContributions / 100));

          console.log('Calcul des taxes:', {
            plusValue: capitalGain.toFixed(2) + '€',
            flatTax: flatTaxAmount.toFixed(2) + '€',
            prelevementsSociaux: socialContributionsAmount.toFixed(2) + '€',
            totalTaxes: (flatTaxAmount + socialContributionsAmount).toFixed(2) + '€'
          });

          return {
            transaction,
            capitalGain,
            flatTax: flatTaxAmount,
            socialContributions: socialContributionsAmount,
            totalTax: flatTaxAmount + socialContributionsAmount
          };
        });
      });

      setTransactionResults(results);
    } catch (err: any) {
      console.error('Erreur lors du calcul des taxes:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleExampleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedExample(value);
    
    const example = examples[value as keyof typeof examples];
    if (example) {
      // setFormData({
      //   buyDate: example.data.buyDate,
      //   sellDate: example.data.sellDate,
      //   crypto: example.data.crypto,
      //   amount: example.data.amount,
      //   buyPrice: example.data.buyPrice,
      //   sellPrice: example.data.sellPrice
      // });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Calcul des plus-values sur transactions
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Les calculs sont basés sur vos transactions réelles enregistrées. Le seuil d'imposition est de {ANNUAL_THRESHOLD}€ par an.
      </Typography>

      <Button
        variant="contained"
        onClick={calculateTaxes}
        disabled={loading}
        sx={{ mb: 3 }}
      >
        {loading ? 'Calcul en cours...' : 'Tester les calculs'}
      </Button>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {transactionResults.length > 0 && (
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', padding: '8px' }}>Date</th>
                <th style={{ textAlign: 'left', padding: '8px' }}>Crypto</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Montant</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Prix total</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Plus-value</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Impôt forfaitaire</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Prélèvements sociaux</th>
                <th style={{ textAlign: 'right', padding: '8px' }}>Total taxes</th>
              </tr>
            </thead>
            <tbody>
              {transactionResults.map((result, index) => (
                <tr key={index}>
                  <td style={{ padding: '8px' }}>{formatDate(result.transaction.date)}</td>
                  <td style={{ padding: '8px' }}>{result.transaction.crypto}</td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.transaction.amount} {result.transaction.crypto}
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.transaction.total.toFixed(2)} €
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.capitalGain.toFixed(2)} €
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.flatTax.toFixed(2)} €
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.socialContributions.toFixed(2)} €
                  </td>
                  <td style={{ textAlign: 'right', padding: '8px' }}>
                    {result.totalTax.toFixed(2)} €
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default TaxSimulator;
