import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { AuthProvider } from './context/AuthContext';
import darkTheme from './theme/darkTheme';
import Background from './components/Background/Background';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Layout from './components/Layout/Layout';
import PrivateRoute from './components/PrivateRoute';
import TransactionsPage from './pages/Transactions/TransactionsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import TaxSimulator from './pages/TaxSimulator';
import ExamplePage from './pages/Example/ExamplePage';

// Temporary placeholder components
const Reports = () => <div>Rapports (à venir)</div>;

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Background>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }
              >
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="transactions" element={<TransactionsPage />} />
                <Route path="reports" element={<Reports />} />
                <Route path="simulator" element={<TaxSimulator />} />
                <Route path="example" element={<ExamplePage />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </AuthProvider>
      </Background>
    </ThemeProvider>
  );
}

export default App;
