import { Transaction } from '../types/transaction';
import { db } from '../config/firebase';
import { collection, getDocs, query, where, addDoc, deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const COLLECTION_NAME = 'transactions';

interface FirestoreTransaction extends Omit<Transaction, 'date' | 'createdAt' | 'updatedAt'> {
  date: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const getUserTransactions = async (): Promise<Transaction[]> => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    const transactionsRef = collection(db, COLLECTION_NAME);
    const q = query(transactionsRef, where('userId', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => {
      const data = doc.data() as FirestoreTransaction;
      const transaction: Transaction = {
        id: doc.id,
        userId: data.userId,
        type: data.type,
        crypto: data.crypto,
        amount: data.amount,
        price: data.price,
        total: data.total,
        date: data.date.toDate().getTime(),
        fees: data.fees,
        notes: data.notes,
        createdAt: data.createdAt.toDate(),
        updatedAt: data.updatedAt.toDate()
      };
      return transaction;
    });
  } catch (error: any) {
    throw new Error(`Error fetching transactions: ${error.message}`);
  }
};

export const addTransaction = async (transactionData: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('User not authenticated');
  }

  const now = new Date();
  const transactionWithUser = {
    ...transactionData,
    crypto: transactionData.crypto?.toUpperCase() || '',
    userId: user.uid,
    createdAt: now,
    updatedAt: now
  };

  try {
    const docRef = await addDoc(collection(db, COLLECTION_NAME), {
      ...transactionWithUser,
      date: Timestamp.fromDate(new Date(transactionWithUser.date)),
      createdAt: Timestamp.fromDate(transactionWithUser.createdAt),
      updatedAt: Timestamp.fromDate(transactionWithUser.updatedAt)
    } as FirestoreTransaction);
    
    return {
      id: docRef.id,
      userId: docRef.id,
      type: transactionWithUser.type,
      crypto: transactionWithUser.crypto,
      amount: transactionWithUser.amount,
      price: transactionWithUser.price,
      total: transactionWithUser.total,
      date: transactionWithUser.date instanceof Date ? transactionWithUser.date.getTime() : transactionWithUser.date,
      fees: transactionWithUser.fees,
      notes: transactionWithUser.notes,
      createdAt: transactionWithUser.createdAt,
      updatedAt: transactionWithUser.updatedAt
    } as Transaction;
  } catch (error: any) {
    throw new Error(`Error adding transaction: ${error.message}`);
  }
};

export const deleteTransaction = async (transactionId: string) => {
  const docRef = doc(db, COLLECTION_NAME, transactionId);
  await deleteDoc(docRef);
};

export const updateTransaction = async (transactionId: string, transactionData: Partial<Transaction>) => {
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    const docRef = doc(db, COLLECTION_NAME, transactionId);
    const updateData: any = {
      ...transactionData,
      crypto: transactionData.crypto?.toUpperCase() || undefined,
      updatedAt: Timestamp.fromDate(new Date())
    };

    if (transactionData.date) {
      updateData.date = Timestamp.fromDate(new Date(transactionData.date));
    }

    await updateDoc(docRef, updateData);
    return {
      id: transactionId,
      userId: user.uid,
      type: updateData.type,
      crypto: updateData.crypto,
      amount: updateData.amount,
      price: updateData.price,
      total: updateData.total,
      date: updateData.date?.toDate().getTime(),
      fees: updateData.fees,
      notes: updateData.notes,
      createdAt: updateData.createdAt?.toDate(),
      updatedAt: updateData.updatedAt?.toDate()
    } as Transaction;
  } catch (error: any) {
    throw new Error(`Error updating transaction: ${error.message}`);
  }
};
