import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import TransactionForm from '../../components/Transaction/TransactionForm';
import { Transaction } from '../../types/transaction';
import { addTransaction, getUserTransactions, updateTransaction, deleteTransaction } from '../../services/transactions';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const TransactionsPage: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const data = await getUserTransactions();
      setTransactions(data);
      setError(null);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForm = () => {
    setSelectedTransaction(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedTransaction(null);
  };

  const handleEdit = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setOpenForm(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette transaction ?')) {
      try {
        setLoading(true);
        await deleteTransaction(id);
        await fetchTransactions();
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (transactionData: Omit<Transaction, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    try {
      setLoading(true);
      if (selectedTransaction?.id) {
        await updateTransaction(selectedTransaction.id, transactionData);
      } else {
        await addTransaction(transactionData);
      }
      await fetchTransactions();
      handleCloseForm();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour calculer la répartition des cryptomonnaies
  const calculateCryptoDistribution = () => {
    const distribution: { [key: string]: number } = {};
    
    transactions.forEach((transaction) => {
      if (!distribution[transaction.crypto]) {
        distribution[transaction.crypto] = 0;
      }
      
      switch (transaction.type) {
        case 'achat':
          distribution[transaction.crypto] += transaction.total;
          break;
        case 'vente':
          distribution[transaction.crypto] -= transaction.total;
          break;
        case 'don_entrant':
          distribution[transaction.crypto] += transaction.amount * transaction.price;
          break;
        case 'don_sortant':
          distribution[transaction.crypto] -= transaction.amount * transaction.price;
          break;
      }
    });

    // Filtrer les valeurs négatives ou nulles
    return Object.fromEntries(
      Object.entries(distribution).filter(([_, value]) => value > 0)
    );
  };

  const calculateInvestmentSummary = () => {
    let totalInvested = 0;
    let totalSold = 0;

    transactions.forEach((transaction) => {
      switch (transaction.type) {
        case 'achat':
          totalInvested += transaction.total;
          break;
        case 'vente':
          totalSold += transaction.total;
          break;
      }
    });

    return {
      totalInvested,
      totalSold,
      netInvestment: totalInvested - totalSold
    };
  };

  const formatCurrency = (amount: number) => {
    return amount.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
  };

  const pieChartData = {
    labels: Object.keys(calculateCryptoDistribution()),
    datasets: [
      {
        data: Object.values(calculateCryptoDistribution()),
        backgroundColor: [
          '#FF6B6B', // Rouge chaud
          '#4D96FF', // Bleu chaleureux
          '#FFD93D', // Jaune chaud
          '#6BCB77', // Vert chaleureux
          '#9B6BFF', // Violet chaleureux
          '#FFA06B', // Orange chaud
          '#FF6B9B', // Rose chaud
          '#6BFFD9', // Turquoise chaleureux
          '#FFB84D', // Orange clair
          '#FF9B6B', // Pêche
        ],
        borderColor: 'rgba(255, 255, 255, 0.8)',
        borderWidth: 1,
      },
    ],
  };

  const pieChartOptions = {
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          font: {
            size: 14,
            weight: 'bold' as const,
          },
          padding: 20,
          generateLabels: (chart: any) => {
            const data = chart.data;
            const total = data.datasets[0].data.reduce((a: number, b: number) => a + b, 0);
            return data.labels.map((label: string, index: number) => {
              const value = data.datasets[0].data[index];
              const percentage = ((value / total) * 100).toFixed(1);
              return {
                text: `${label} : ${percentage}% (${value.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })})`,
                fillStyle: data.datasets[0].backgroundColor[index],
                strokeStyle: data.datasets[0].borderColor,
                lineWidth: data.datasets[0].borderWidth,
                hidden: false,
                index: index,
              };
            });
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a: number, b: number) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            const formattedValue = value.toLocaleString('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            });
            return [
              `Montant: ${formattedValue}`,
              `Pourcentage: ${percentage}%`
            ];
          },
        },
      },
      datalabels: {
        display: true,
        color: '#fff',
        font: {
          weight: 'bold' as const,
          size: 14,
        },
        formatter: (value: number, ctx: any) => {
          const total = ctx.dataset.data.reduce((a: number, b: number) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return percentage + '%';
        },
        anchor: 'center' as const,
        align: 'center' as const,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
  };

  if (loading && transactions.length === 0) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Transactions
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenForm}
        >
          Nouvelle Transaction
        </Button>
      </Box>

      {/* Résumé des investissements */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            p: 3, 
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'text.secondary' }}>
              Capital Total Investi
            </Typography>
            <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              {formatCurrency(calculateInvestmentSummary().totalInvested)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            p: 3, 
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'text.secondary' }}>
              Total des Ventes
            </Typography>
            <Typography variant="h4" sx={{ color: 'success.main', fontWeight: 'bold' }}>
              {formatCurrency(calculateInvestmentSummary().totalSold)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            p: 3, 
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'text.secondary' }}>
              Investissement Net
            </Typography>
            <Typography variant="h4" sx={{ 
              color: calculateInvestmentSummary().netInvestment >= 0 ? 'primary.main' : 'error.main',
              fontWeight: 'bold'
            }}>
              {formatCurrency(calculateInvestmentSummary().netInvestment)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Graphique en camembert */}
      <Paper sx={{ 
        p: 3, 
        mb: 4, 
        height: '500px',
        backgroundColor: 'background.paper',
        borderRadius: 2,
        boxShadow: 3,
      }}>
        <Typography variant="h6" gutterBottom sx={{ 
          mb: 3,
          fontWeight: 'bold',
          color: 'text.primary',
        }}>
          Répartition du portefeuille
        </Typography>
        <Box sx={{ 
          height: 'calc(100% - 60px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Pie data={pieChartData} options={pieChartOptions} />
        </Box>
      </Paper>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Crypto</TableCell>
              <TableCell align="right">Montant</TableCell>
              <TableCell align="right">Prix unitaire</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Frais</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {format(new Date(transaction.date), 'dd/MM/yyyy HH:mm', { locale: fr })}
                </TableCell>
                <TableCell>
                  {transaction.type === 'achat' ? 'Achat' :
                   transaction.type === 'vente' ? 'Vente' :
                   transaction.type === 'don_entrant' ? 'Don reçu' : 'Don envoyé'}
                </TableCell>
                <TableCell>{transaction.crypto}</TableCell>
                <TableCell align="right">{transaction.amount} {transaction.crypto}</TableCell>
                <TableCell align="right">{transaction.price} EUR</TableCell>
                <TableCell align="right">{transaction.total} EUR</TableCell>
                <TableCell align="right">{transaction.fees} EUR</TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => handleEdit(transaction)}
                    sx={{ mr: 1 }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDelete(transaction.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {transactions.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography variant="body1" sx={{ py: 2 }}>
                    Aucune transaction
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        maxWidth="sm"
        fullWidth
      >
        <TransactionForm
          onSubmit={handleSubmit}
          initialData={selectedTransaction}
          onClose={handleCloseForm}
        />
      </Dialog>
    </Container>
  );
};

export default TransactionsPage;
