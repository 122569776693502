import React, { useState, useEffect, useCallback } from 'react';
import { Container, Box, Typography } from '@mui/material';
import PortfolioValue from '../../components/Portfolio/PortfolioValue';
import { Transaction } from '../../types/transaction';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';

const DashboardPage: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [user] = useAuthState(auth);

  const fetchUserTransactions = useCallback(async () => {
    if (!user) return;

    try {
      const q = query(
        collection(db, 'transactions'),
        where('userId', '==', user.uid)
      );
      
      const querySnapshot = await getDocs(q);
      const transactionsData: Transaction[] = [];
      
      querySnapshot.forEach((doc) => {
        transactionsData.push({
          id: doc.id,
          ...doc.data()
        } as Transaction);
      });
      
      setTransactions(transactionsData);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchUserTransactions();
    }
  }, [user, fetchUserTransactions]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Tableau de bord
        </Typography>
        
        <PortfolioValue transactions={transactions} />
      </Box>
    </Container>
  );
};

export default DashboardPage;
