import axios from 'axios';

const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3';

// Map des symboles crypto vers les IDs CoinGecko
const cryptoIdMap: { [key: string]: string } = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  BNB: 'binancecoin',
  XRP: 'ripple',
  ADA: 'cardano',
  DOGE: 'dogecoin',
  SOL: 'solana',
  USDT: 'tether',
  USDC: 'usd-coin',
  DAI: 'dai',
};

export const getCurrentPrice = async (symbol: string): Promise<number | null> => {
  try {
    const id = cryptoIdMap[symbol.toUpperCase()];
    if (!id) {
      console.warn(`No CoinGecko ID found for symbol: ${symbol}`);
      return null;
    }

    const response = await axios.get(`${COINGECKO_API_URL}/simple/price`, {
      params: {
        ids: id,
        vs_currencies: 'eur'
      }
    });

    return response.data[id]?.eur || null;
  } catch (error) {
    console.error('Error fetching crypto price:', error);
    return null;
  }
};

export const getPortfolioValue = async (holdings: { symbol: string; amount: number }[]): Promise<{
  totalValue: number;
  details: { symbol: string; amount: number; currentPrice: number; value: number }[];
}> => {
  const details = await Promise.all(
    holdings.map(async ({ symbol, amount }) => {
      const currentPrice = await getCurrentPrice(symbol);
      return {
        symbol,
        amount,
        currentPrice: currentPrice || 0,
        value: (currentPrice || 0) * amount
      };
    })
  );

  const totalValue = details.reduce((sum, item) => sum + item.value, 0);

  return {
    totalValue,
    details
  };
};
