import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7c3aed', // Violet principal
      light: '#9d6dff',
      dark: '#5b21b6',
    },
    secondary: {
      main: '#4f46e5', // Indigo
      light: '#818cf8',
      dark: '#3730a3',
    },
    background: {
      default: 'transparent',
      paper: 'rgba(30, 41, 59, 0.8)',
    },
    text: {
      primary: '#f8fafc',
      secondary: '#94a3b8',
    },
    divider: 'rgba(148, 163, 184, 0.12)',
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
      color: '#f8fafc',
    },
    h6: {
      fontWeight: 600,
      color: '#f8fafc',
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(30, 41, 59, 0.8)',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(148, 163, 184, 0.12)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(30, 41, 59, 0.95)',
          backdropFilter: 'blur(10px)',
          borderRight: '1px solid rgba(148, 163, 184, 0.12)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: 'rgba(30, 41, 59, 0.8)',
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(124, 58, 237, 0.15)',
            '&:hover': {
              backgroundColor: 'rgba(124, 58, 237, 0.25)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(124, 58, 237, 0.1)',
          },
        },
      },
    },
  },
});

export default darkTheme;
